/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js
 * - /npm/@hotwired/turbo@8.0.12/dist/turbo.es2017-umd.min.js
 * - /npm/jquery-validation@1.20.0/dist/jquery.validate.min.js
 * - /npm/jquery-validation@1.20.0/dist/additional-methods.min.js
 * - /npm/form-request-submit-polyfill@2.0.0/form-request-submit-polyfill.min.js
 * - /npm/hammerjs@2.0.8/hammer.min.js
 * - /npm/vanilla-lazyload@19.1.3/dist/lazyload.min.js
 * - /npm/sweetalert2@11.11.0/dist/sweetalert2.all.min.js
 * - /npm/izitoast@1.4.0/dist/js/iziToast.min.js
 * - /npm/swiper@11.1.4/swiper-bundle.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
